import * as React from "react";
import { trigger } from "swr";
import { set, includes, omit } from "lodash";
import { mutateData } from "../client";
import { Label, LargePanelWrapper, ContentLargePanel } from "../styles";
import { Toggle } from "./ui/Toggle";
import { useLocation, useHistory } from "react-router-dom";
import { useKeyUpEsc } from "../hooks/useKeyUpEsc";
import { Input } from "./ui/Input";
import { Chip } from "./ui/Chip";
import { GraphApiTokenEditPanel } from "./GraphApiTokenEditPanel";
import { TikTokTokenEditPanel } from "./TikTokTokenEditPanel";
import { GoogleTokenEditPanel } from "./GoogleTokenEditPanel";
import {TikTokTokenMarketingEditPanel} from "./TikTokTokenMarketingEditPanel";
import { Checkbox } from "./ui/Checkbox";
import { Button } from "./ui/Button";
import { Divider } from "./ui/Divider";
import { TitleAndClose } from "./ui/TitleAndClose";
import { createTokenSuccess, createTokenError } from "../messages";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { useKeyDownEnter } from "../hooks/useKeyDownEnter";
import {
  GraphApiTokenType,
  TokenType,
  GoogleToken,
  CredentialsToken,
  ApiKeyToken,
  toStringTokenType,
  AvailableToken,
  TikTokToken,
  TikTokMarketingToken
} from "../types/Token";
import { CredentialsTokenEditPanel } from "./CredentialsTokenEditPanel";
import { ApiKeyTokenEditPanel } from "./ApiKeyTokenEditPanel";
import styled from "styled-components";
import { usePreviousData } from "../hooks/usePreviousData";

const WrapperChip = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;

  & > div {
    flex-basis: 50%;
    margin-bottom: 10px;
  }

  & > div:nth-child(odd) {
    padding-right: 8px;
  }
  & > div:nth-child(even) {
    padding-left: 8px;
  }
`;

export type GraphApiTokenDetails = {
  appSecret: string;
  token: string;
  expiryDate: string | null;
  type: GraphApiTokenType;
};

export type TokenDetails = {
  [TokenType.graph]: GraphApiTokenDetails;
  [TokenType.google]: GoogleToken;
  [TokenType.credentials]: CredentialsToken;
  [TokenType.apiKey]: ApiKeyToken;
  [TokenType.tiktok]: TikTokToken;
  [TokenType.tiktokMarketing]: TikTokMarketingToken;
};

const AddTokenPanel: React.FC = () => {
  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const [tokenDetailsIsEmpty, setTokenDetailsIsEmpty] = React.useState<boolean>(true);

  const [checkCreateAnother, setCheckCreateAnother] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [description, setDescription] = React.useState("");
  const [active, setActive] = React.useState(true);
  const [tokenType, setTokenType] = React.useState<TokenType>(TokenType.graph);

  const graphTokenDetailsInitialState: GraphApiTokenDetails = {
    appSecret: "",
    token: "",
    expiryDate: null,
    type: GraphApiTokenType.PAGE,
  };

  const googleTokenDetailsInitialState: GoogleToken = {
    accessToken: "",
    refreshToken: "",
    clientId: "",
    clientSecret: "",
    expiryDate: "",
    redirectUri: "",
  };

  const credentialsTokenInitialState: CredentialsToken = {
    username: "",
    password: "",
  };

  const apiKeyTokenInitialState: ApiKeyToken = {
    apiKey: "",
  };

  const tiktokTokenDetailsInitialState: TikTokToken = {
    accessToken: "",
    refreshToken: "",
    clientId: "",
    clientSecret: "",
    expiryDate:"",
    expiryRefreshToken:""

   
  };


  const tiktokMarketingTokenDetailsInitialState: TikTokMarketingToken = {
    token: "",
    appId: "",
    expiryDate:""
   
  };

  const tokenDetailsInitialState = {
    [TokenType.graph]: graphTokenDetailsInitialState,
    [TokenType.google]: googleTokenDetailsInitialState,
    [TokenType.credentials]: credentialsTokenInitialState,
    [TokenType.apiKey]: apiKeyTokenInitialState,
    [TokenType.tiktok]: tiktokTokenDetailsInitialState,
    [TokenType.tiktokMarketing]: tiktokMarketingTokenDetailsInitialState,
  };

  const [tokenDetails, setTokenDetails] = React.useState<TokenDetails>(tokenDetailsInitialState);

  const location = useLocation();
  const history = useHistory();

  const onClose = () =>
    history.push({
      pathname: location.pathname,
    });

  const updateTokenDetails = (key: string, value: any) => {
    setTokenDetails((state) => ({
      ...state,
      [tokenType]: { ...set(tokenDetails[tokenType], key, value) },
    }));
  };

  const create = async () => {
    try {
      setLoading(true);

      const token = tokenDetails[tokenType] as AvailableToken;
      let expiryDate: { expiryDate: string | null } | null = null;
      let expiryRefreshToken: { expiryRefreshToken: string | null } | null = null;
      if ("expiryDate" in token) {
        expiryDate = { expiryDate: token.expiryDate ? `${token.expiryDate}T00:00` : null };
      }
      if ("expiryRefreshToken" in token) {
        expiryRefreshToken = { expiryRefreshToken: token.expiryRefreshToken ? `${token.expiryRefreshToken}T00:00` : null };
      }

      await mutateData("POST", "/tokens", {
        description,
        active,
        token: {
          ...tokenDetails[tokenType],
          ...expiryDate,
          ...expiryRefreshToken,
        },
      });

      onOpenSnackbar(createTokenSuccess);

      trigger("/token-with-publishers");

      if (checkCreateAnother) {
        setDescription("");
        setActive(true);
        setTokenDetails({
          [TokenType.graph]: graphTokenDetailsInitialState,
          [TokenType.google]: googleTokenDetailsInitialState,
          [TokenType.credentials]: credentialsTokenInitialState,
          [TokenType.apiKey]: apiKeyTokenInitialState,
          [TokenType.tiktok]: tiktokTokenDetailsInitialState,
          [TokenType.tiktokMarketing]: tiktokMarketingTokenDetailsInitialState,
        });
        setLoading(false);
      } else {
        onClose();
      }
    } catch (err) {
      onOpenSnackbar(createTokenError, "error");
    }
  };

  useKeyUpEsc(onClose);
  useKeyDownEnter(create);

  const prevTokenType = usePreviousData(tokenType);

  React.useEffect(() => {
    if (prevTokenType !== tokenType) {
      setTokenDetailsIsEmpty(true);
    } else {
      let isEmpty = true;
      if (tokenType === TokenType.graph) {
        const exactTokenDetails = omit(tokenDetails[TokenType.graph], "expiryDate");

        isEmpty = includes(Object.values(exactTokenDetails), "");
      } else {
        isEmpty = includes(Object.values(tokenDetails[tokenType]), "");
      }

      setTokenDetailsIsEmpty(isEmpty);
    }
  }, [tokenDetails, tokenType]);

  const disabledButton = !description || !tokenType || tokenDetailsIsEmpty;

  const getEditPanelByTokenType = (tokenType: TokenType) => {
    const components = {
      [TokenType.graph]: <GraphApiTokenEditPanel {...tokenDetails.graph} updateValue={updateTokenDetails} />, //same for linkedin
      [TokenType.google]: <GoogleTokenEditPanel {...tokenDetails.google} updateValue={updateTokenDetails} />,
      [TokenType.credentials]: (
        <CredentialsTokenEditPanel {...tokenDetails.credentials} updateValue={updateTokenDetails} />
      ),
      [TokenType.apiKey]: <ApiKeyTokenEditPanel {...tokenDetails.apiKey} updateValue={updateTokenDetails} />,
      [TokenType.tiktok]: <TikTokTokenEditPanel {...tokenDetails.tiktok} updateValue={updateTokenDetails} />,
      [TokenType.tiktokMarketing]: <TikTokTokenMarketingEditPanel {...tokenDetails.tiktokMarketing} updateValue={updateTokenDetails} />,
    };
    

    return components[tokenType];
  };

  return (
    <LargePanelWrapper>
      <TitleAndClose title="Create Token" onClose={onClose} />
      <ContentLargePanel>
        <div style={{ display: "flex", marginBottom: 25 }}>
          <div style={{ flexBasis: "50%", paddingRight: 15 }}>
            <Input
              label="description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.currentTarget.value)}
            />
          </div>
          <div style={{ flexBasis: "50%", paddingLeft: 15 }}>
            <Toggle label="active" checked={active} onClick={() => setActive(!active)} size="medium" />
          </div>
        </div>
        <Divider />
        <div style={{ display: "flex", flexDirection: "column", marginTop: 25 }}>
          <Label>Token type</Label>
          <WrapperChip>
            {Object.values(TokenType).map((token, i) => {
              return (
                <div key={i}>
                  <Chip
                    selected={tokenType === token}
                    onClick={(_) => {
                      setTokenType(token);
                      setTokenDetails((state) => ({
                        ...state,
                        [token]: tokenDetailsInitialState[token],
                      }));
                    }}
                  >
                    {toStringTokenType(token)}
                  </Chip>
                </div>
              );
            })}
          </WrapperChip>
        </div>
        {getEditPanelByTokenType(tokenType)}
      </ContentLargePanel>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-end",
          position: "fixed",
          bottom: 0,
          marginBottom: 20,
        }}
      >
        <Checkbox
          checked={checkCreateAnother}
          onClick={() => setCheckCreateAnother(!checkCreateAnother)}
          label="Create another"
        />
        <Button onClick={create} style={{ marginLeft: 10 }} disabled={disabledButton} loading={loading}>
          Add token
        </Button>
      </div>
    </LargePanelWrapper>
  );
};

export { AddTokenPanel };
