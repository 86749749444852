import * as React from "react";
import moment from "moment";
import client, { mutateData } from "../client";
import { Dialog, DialogableProps } from "./ui/Dialog";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import { TokenRecord, isGraphApiToken, isGoogleApiToken, isCredentialsToken, isApiKeyToken , isTikTokToken} from "../types/Token";
import { Divider } from "./ui/Divider";
import { Toggle } from "./ui/Toggle";
import { SnackbarContext } from "../providers/SnackbarProvider";
import { editTokenError, editTokenSuccess } from "../messages";
import { trigger } from "swr";
import { useKeyDownEnter } from "../hooks/useKeyDownEnter";
import { DateField } from "./ui/DateField";
import { PublisherRecord } from "../types/Publisher";
import { ColorThemeContext } from "../providers/ColorThemeProvider";
import { colorTheme } from "../theme";
import { VisiblePasswordIcon } from "./CredentialsTokenEditPanel";

type Props = DialogableProps & {
  selectedToken: TokenRecord & { publishers: Array<PublisherRecord> };
};

const DialogEditToken: React.FC<Props> = ({ open, onClose, selectedToken }) => {
  const { onOpenSnackbar } = React.useContext(SnackbarContext);

  const expiryDateVisible = isGraphApiToken(selectedToken.token) || isGoogleApiToken(selectedToken.token);
  const expiryRefreshTokenVisible = isGoogleApiToken(selectedToken.token) || isTikTokToken(selectedToken.token);

  const { themeOption } = React.useContext(ColorThemeContext);

  const [visiblePassword, setVisibilePassword] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>(selectedToken.description);
  const [active, setActive] = React.useState<boolean>(selectedToken.active);
  const [expiryDate, setExpiryDate] = React.useState<string | null>(null);
  const [expiryRefreshToken, setExpiryRefreshToken] = React.useState<string | null>(null);

  // Graph Api token state
  const [token, setToken] = React.useState<string>("");
  const [appSecret, setAppSecret] = React.useState<string>("");
  // Credentials token state
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  // Api Key token state
  const [apiKey, setApiKey] = React.useState<string>("");

  React.useEffect(() => {
    if (isGraphApiToken(selectedToken.token)) {
      setToken(selectedToken.token.token);
      setAppSecret(selectedToken.token.appSecret);
    }
    if (isCredentialsToken(selectedToken.token)) {
      setUsername(selectedToken.token.username);
      setPassword(selectedToken.token.password);
    }

    if (isApiKeyToken(selectedToken.token)) {
      setApiKey(selectedToken.token.apiKey);
    }

    if ("expiryDate" in selectedToken.token) {
      const formattedExpiryDate = selectedToken.token.expiryDate
        ? moment(selectedToken.token.expiryDate).format("YYYY-MM-DD")
        : null;

      setExpiryDate(formattedExpiryDate);
    }
    if ("expiryRefreshToken" in selectedToken.token) {
      const formattedExpiryRefreshToken = selectedToken.token.expiryRefreshToken
        ? moment(selectedToken.token.expiryRefreshToken).format("YYYY-MM-DD")
        : null;

        setExpiryRefreshToken(formattedExpiryRefreshToken);
    }
  }, []);

  const editingTokenGraphApiComponent = () => {
    if (isGraphApiToken(selectedToken.token)) {
      return (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
          <Input
            label="App secret"
            placeholder="App secret"
            value={appSecret}
            onChange={(e) => setAppSecret(e.currentTarget.value)}
            wrapperStyle={{ flexBasis: "50%", paddingRight: 15 }}
          />
          <Input
            label="Token"
            placeholder="Token"
            value={token}
            onChange={(e) => setToken(e.currentTarget.value)}
            wrapperStyle={{ flexBasis: "50%", paddingLeft: 15 }}
          />
        </div>
      );
    }

    if (isCredentialsToken(selectedToken.token)) {
      return (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
          <Input
            placeholder="Username"
            label="username"
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
            wrapperStyle={{ flexBasis: "50%", paddingRight: 15 }}
            autoComplete="off"
            data-lpignore="true"
          />
          <div style={{ position: "relative", flexBasis: "50%", paddingLeft: 15 }}>
            <form autoComplete="off">
              <Input
                placeholder="Password"
                label="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                type={visiblePassword ? "text" : "password"}
                autoComplete="off"
                data-lpignore="true"
              />
              <VisiblePasswordIcon
                name={visiblePassword ? "hidden" : "view"}
                style={{ position: "absolute", right: 8, bottom: 5 }}
                color={colorTheme[themeOption].primary}
                onClick={() => setVisibilePassword((show) => !show)}
              />
            </form>
          </div>
        </div>
      );
    }

    if (isApiKeyToken(selectedToken.token)) {
      return (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
          <Input
            label="Api key"
            placeholder="Api key"
            value={apiKey}
            onChange={(e) => setApiKey(e.currentTarget.value)}
            wrapperStyle={{ flexBasis: "50%", paddingRight: 15 }}
          />
        </div>
      );
    }

    return null;
  };

  const save = async () => {
    try {
      setLoadingSave(true);
      const newExpiryDate = expiryDate ? `${expiryDate}T00:00` : null;

      const newExpiryRefreshToken = expiryRefreshToken ? `${expiryRefreshToken}T00:00` : null;

      if (isGraphApiToken(selectedToken.token)) {
        await mutateData("PUT", "/tokens", {
          ...selectedToken,
          description,
          active,
          token: {
            ...selectedToken.token,
            expiryDate: newExpiryDate,
            token,
            appSecret,
          },
        });
      }
      if (isGoogleApiToken(selectedToken.token)) { //same as linkdin
        await mutateData("PUT", "/tokens", {
          ...selectedToken,
          description,
          active,
          token: {
            ...selectedToken.token,
            expiryDate: newExpiryDate,
            expiryRefreshToken: newExpiryRefreshToken
          },
        });
      }

      if (isCredentialsToken(selectedToken.token)) {
        await mutateData("PUT", "/tokens", {
          ...selectedToken,
          description,
          active,
          token: {
            username,
            password,
          },
        });
      }
      if (isApiKeyToken(selectedToken.token)) {
        await mutateData("PUT", "/tokens", {
          ...selectedToken,
          description,
          active,
          token: {
            ...selectedToken.token,
            apiKey,
          },
        });
      }
      onOpenSnackbar(editTokenSuccess);
      trigger("/token-with-publishers");
    } catch (err) {
      onOpenSnackbar(editTokenError, "error");
    }

    onClose();
  };

  useKeyDownEnter(save);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleDialog="Edit Token"
      wrapperStyle={{ width: 500 }}
      buttonSection={
        <>
          <Button onClick={onClose} undo>
            Undo
          </Button>
          <Button onClick={save} style={{ marginLeft: 10 }} loading={loadingSave}>
            Save
          </Button>
        </>
      }
    >
      <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
        <Input
          label="description"
          placeholder="Description here"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          wrapperStyle={{ flexBasis: "50%", paddingRight: 15 }}
        />
        <Toggle
          label="active"
          checked={active}
          onClick={() => setActive((active) => !active)}
          size="medium"
          style={{ flexBasis: "50%", paddingLeft: 15 }}
        />
      </div>
      <Divider style={{ marginBottom: 25 }} />
      {editingTokenGraphApiComponent()}
      {expiryDateVisible && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
          <DateField
            label="Expiry date"
            onChange={(e) => setExpiryDate(e.currentTarget.value)}
            value={expiryDate || ""}
            wrapperStyle={{ width: "50%", paddingRight: 15 }}
          />
        </div>
      )}
      {expiryRefreshTokenVisible && (

        <div style={{ display: "flex", alignItems: "center", marginBottom: 25 }}>
            <DateField
              label="Expiry Refresh Token"
              onChange={(e) => setExpiryRefreshToken(e.currentTarget.value)}
              value={expiryRefreshToken || ""}
              wrapperStyle={{ width: "50%", paddingRight: 5 }}
            />
      </div>
      )}
    </Dialog>
  );
};

export { DialogEditToken };
