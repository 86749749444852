import * as React from "react";
import moment from "moment";
import { Info } from "../styles";
import styled from "styled-components";
import {
  TokenRecord,
  isGraphApiToken,
  GoogleToken,
  isGoogleApiToken,
  isCredentialsToken,
  isApiKeyToken,
  isTikTokMarketingToken,
  isTikTokToken
} from "../types/Token";
import { Icon } from "./ui/Icon";
import { capitalize } from "lodash";
import { IconCopyText } from "./IconCopyText";

interface Props {
  tokenRecord: TokenRecord;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, auto));
  gap: 15px;
`;

const InfoStyled = styled(Info)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TitleKey = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  padding-right: 5px;
`;

const TokenAdditionalInfo: React.FC<Props> = ({ tokenRecord }) => {
  if (isGraphApiToken(tokenRecord.token)) {
    const token = tokenRecord.token;
    return (
      <Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Last used</InfoStyled>
          <TitleKey>{moment(tokenRecord.lastUsed).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        {token.expiryDate && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InfoStyled>Expiry date</InfoStyled>
            <TitleKey>{moment(token.expiryDate).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Type</InfoStyled>
          <TitleKey>{capitalize(token.type)}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Token</InfoStyled>
          <IconCopyText text={token.token} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>App Secret</InfoStyled>
          <IconCopyText text={token.appSecret} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>App Secret proof</InfoStyled>
          <IconCopyText text={token.appSecretProof} />
        </div>
      </Wrapper>
    );
  }

  if (isGoogleApiToken(tokenRecord.token)) {
    const token = tokenRecord.token;
    return (
      <Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Last used</InfoStyled>
          <TitleKey>{moment(tokenRecord.lastUsed).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Expiry date</InfoStyled>
          <TitleKey>{moment(token.expiryDate).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Client id</InfoStyled>
          <IconCopyText text={token.clientId} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Client secret</InfoStyled>
          <IconCopyText text={token.clientSecret} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Access token</InfoStyled>
          <IconCopyText text={token.accessToken} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Refresh token</InfoStyled>
          <IconCopyText text={token.refreshToken} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Redirect uri</InfoStyled>
          <IconCopyText text={token.redirectUri} />
        </div>
      </Wrapper>
    );
  }

  if (isTikTokToken(tokenRecord.token)) {
    const token = tokenRecord.token;
    return (
      <Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Last used</InfoStyled>
          <TitleKey>{moment(tokenRecord.lastUsed).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Expiry date</InfoStyled>
          <TitleKey>{moment(token.expiryDate).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Expiry Refresh Token</InfoStyled>
          <TitleKey>{moment(token.expiryRefreshToken).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Client id</InfoStyled>
          <IconCopyText text={token.clientId} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Client secret</InfoStyled>
          <IconCopyText text={token.clientSecret} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Access token</InfoStyled>
          <IconCopyText text={token.accessToken} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Refresh token</InfoStyled>
          <IconCopyText text={token.refreshToken} />
        </div>
       
      </Wrapper>
    );
  }

 

  if (isCredentialsToken(tokenRecord.token)) {
    const token = tokenRecord.token;
    return (
      <Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Username</InfoStyled>
          <TitleKey>{token.username}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Password</InfoStyled>
          <IconCopyText text={token.password} textIsVisible={false} />
        </div>
      </Wrapper>
    );
  }

  if (isApiKeyToken(tokenRecord.token)) {
    const token = tokenRecord.token;
    return (
      <Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Api key</InfoStyled>
          <IconCopyText text={token.apiKey} />
        </div>
      </Wrapper>
    );
  }


  if (isTikTokMarketingToken(tokenRecord.token)) {
    const token = tokenRecord.token;

    return (
      <Wrapper>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Last used</InfoStyled>
          <TitleKey>{moment(tokenRecord.lastUsed).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Expiry date</InfoStyled>
          <TitleKey>{moment(token.expiryDate).format("DD/MM/YYYY, HH:mm:ss")}</TitleKey>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>App id</InfoStyled>
          <IconCopyText text={token.appId} />
        </div>
       
        <div style={{ display: "flex", flexDirection: "column" }}>
          <InfoStyled>Token</InfoStyled>
          <IconCopyText text={token.token} />
        </div>
      
     
      </Wrapper>
    );
  }

  return <span>ERRORE!</span>;
};

export { TokenAdditionalInfo };
