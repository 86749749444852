export enum Platform {
  FB = "FB",
  FBG = "FBG",
  FMK = "FMK",
  IG = "IG",
  YT = "YT",
  NVS = "NVS",
  CDT = "CDT",
  GA = "GA",
  TW = "TW",
  TK = "TK",
  TKMK="TKMK",
  LI="LI",
}

const stringifiedPlatforms = {
  [Platform.FB]: "Facebook",
  [Platform.FBG]: "Facebook Group",
  [Platform.FMK]: "Facebook Marketing",
  [Platform.IG]: "Instagram",
  [Platform.YT]: "Youtube",
  [Platform.NVS]: "Navision",
  [Platform.CDT]: "CrowdTangle",
  [Platform.GA]: "Google Analytics",
  [Platform.TW]: "Twitter",
  [Platform.TK]: "TikTok",
  [Platform.TKMK]: "TikTok Marketing",
  [Platform.LI]: "LinkedIn",
};

export const toStringPlatform = (platform: Platform) => stringifiedPlatforms[platform];
